import * as React from 'react'
import { connect } from 'react-redux'
import { IoPlay, IoPause, IoPlaySkipForward, IoPlaySkipBack } from 'react-icons/io5'
import WaveSurfer from 'wavesurfer.js'
import Select from 'react-select'

import { getWaveformData } from './waveform'
import { AudioPlayerDetails } from '../../../reducers/audioPlayer'
import { Audio } from '../../../actions/audioPlayer'
import { nextQueueItem, prevQueueItem } from '../../../actions/playerQueue'

import './PrettyAudio.scss'
import classNames from 'classnames'
import { secondsToTimeString } from 'components/utils/humanTime'
import { PlayerQueueState } from 'reducers/playerQueue'
import { Item } from 'types/Item'
import config from '../../../config.js'

interface Props {
  data: AudioPlayerDetails
  noClick?: boolean
  audioPlayer: Function
  onLoad?: Function
  inEmbedMode?: boolean
  autoplay?: boolean,
  playlistMode?: boolean,
  queueItems?: Item[],
  queueNext: Function,
  queuePrev: Function
}

interface State {
  paused: boolean
  wavesurfer?: WaveSurfer
  loaded: boolean
  duration: number
  currentTime: number
}

class PrettyAudio extends React.Component<Props, State> {
  _isMounted
  _waveContainer = React.createRef<HTMLDivElement>()

  constructor(props: Props) {
    super(props)

    this._isMounted = false

    this.state = {
      paused: true,
      loaded: false,
      duration: 0,
      currentTime: 0
    }
  }

  componentDidMount(): void {
    this._isMounted = true
    this.init()
  }

  componentWillUnmount(): void {
    this._isMounted = false
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.data.url !== prevProps.data.url) {
      this.init()
    }
  }

  init = async () => {
    let loaded = this.state.loaded
    let wavesurfer = this.state.wavesurfer

    if ((!wavesurfer || !loaded) && this._waveContainer.current) {
      wavesurfer = WaveSurfer.create({
        height: this.props.inEmbedMode ? 60 : window.innerWidth > 767 ? 180 : 100,
        barHeight: 20,
        barWidth: window.innerWidth > 767 ? 8 : 4,
        barGap: 2.5,
        barRadius: 4,

        container: this._waveContainer.current,
        backend: 'MediaElement',
        responsive: true,
        partialRender: false,

        progressColor: '#00000050',
        waveColor: '#FFFFFF',
        cursorColor: '#888888',
        hideScrollbar: true,
        normalize: true,
        cursorWidth: 2
      })

      if (this.props.data.url) {
        const waveform = await getWaveformData(this.props.data.s3_key)

        wavesurfer.load(this.props.data.url, waveform, 'auto')
        
        wavesurfer.on('ready', () => {
          const duration = Math.round(wavesurfer?.getDuration() as number)
          this.setState(prev => ({ ...prev, duration }))
          if (this.props.autoplay) this.playPause()
        })
        wavesurfer.on('pause', () => {
          this.setState(prev => ({ ...prev, paused: true }))
        })
        wavesurfer.on('audioprocess', time => {
          const roundedTime = Math.round(time)
          roundedTime !== this.state.currentTime && this.setState(prev => ({ ...prev, currentTime: roundedTime }))
        })
        wavesurfer.on('finish', () => {
          if (this.props.queueItems && this.props.queueItems.length > 1) {
            this.props.queueNext()
          }
        })
      }
    }

    if (typeof this.props.onLoad === 'function') {
      this.props.onLoad()
    }

    if (this._isMounted) {
      this.setState(prev => ({ ...prev, wavesurfer: wavesurfer, loaded: true }))
    }

    if (this.props.inEmbedMode) {
      const { id, item_subtype, date, creators, title, url, isCollection } = this.props.data
      this.props.audioPlayer(true, { id, item_subtype, date, creators, title, url, isCollection })
    }
  }

  playPause() {
    if (this.state.paused) {
      this.state.wavesurfer?.play()
      this.setState(prev => ({ ...prev, paused: false}))
    } else {
      this.state.wavesurfer?.pause()
      this.setState(prev => ({ ...prev, paused: true}))
    }
  }

  render() {
    const classes = classNames('pretty-audio', { 'pretty-audio--embed': this.props.inEmbedMode })

    const playlistMode = this.props.playlistMode || this.props.queueItems?.length
    const isWav = this.props.data.s3_key?.endsWith('.wav')
    const isConverted = this.props.data.url.endsWith('.m4a')
    const originalFile = config.urls.BASE_CONTENT_URL + this.props.data.s3_key

    return (
      <div className={ classes }>
        <div className="pretty-audio__wave-container" ref={ this._waveContainer } onClick={() => { 
          if (!this.props.noClick && this.state.paused) this.playPause() 
        }} />
        <div className="pretty-audio__controls">
          <div>
            { playlistMode ?
              <button className="btn btn--plain mr-4" onClick={ () => this.props.queuePrev() }>
                <><IoPlaySkipBack /></>
              </button>
              : <></>
            }
            <button className="btn btn--plain" onClick={ () => this.playPause() }>
              { this.state.paused
                ? <><IoPlay />{ !playlistMode && <span>Play</span> }</>
                : <><IoPause />{ !playlistMode && <span>Pause</span> }</>
              }
            </button>
            { playlistMode ?
              <button className="btn btn--plain ml-4" onClick={ () => this.props.queueNext() }>
                <><IoPlaySkipForward /></>
              </button>
              : <></>
            }
          </div>
          { this.props.inEmbedMode &&
            <div className="pretty-audio__title"><span>{ this.props.data.title }</span></div>
          }
          <div className="pretty-audio__right">
            <div className="pretty-audio__quality">Lossless</div>
            <div className="pretty-audio__time">
              { secondsToTimeString(this.state.currentTime) } / { secondsToTimeString(this.state.duration) }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: { playerQueue: PlayerQueueState }) => ({ // eslint-disable-line @typescript-eslint/no-explicit-any
  queueItems: state.playerQueue.items,
})

export default connect(mapStateToProps, { audioPlayer: Audio, queueNext: nextQueueItem, queuePrev: prevQueueItem })(PrettyAudio)
