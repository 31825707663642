import * as React from 'react';
import { Col, Progress, Row, Input, Form, FormFeedback, Button } from 'reactstrap';
import $ from 'jquery';
import { Auth, Storage, API } from 'aws-amplify';
import Dropzone from 'react-dropzone';
import { v1 as uuid } from 'uuid';
import Popup from 'reactjs-popup';

import { getEmbedVideoThumbnailUrl } from '../utils/FilePreview';
import { last } from 'lodash-es';
import { AuthContext } from 'providers/AuthProvider';

import 'styles/components/_dropzone.scss';
import 'styles/components/_reactTags.scss';
import { Alerts, ErrorMessage } from '../utils/alerts';
import classNames from 'classnames';

interface State extends Alerts {
  files: Files;
  rejectedFiles: Rejections;
  uploadedFiles: Files;
  videoUrlValue: string | null;
  videoUrls: string[] | null;
  videoUrlError: boolean;
  archiveUrl: string;
  archiveUrlError: boolean;
}
interface Props {
  callback: Function;
  isAdmin?: boolean;
}
interface Files {
  [id: string]: File;
}
interface Rejections {
  [id: string]: Rejection;
}
interface Rejection {
  errors: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  file: File;
}
export interface File {
  uuid: string;
  name: string;
  preview: string;
  size: number;
  type: string;
  uploaded: boolean;
  s3key: string;
  original: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  url?: string;
  lossless?: boolean;
}

export class FileUpload extends React.Component<Props, State> {
  static contextType = AuthContext;
  _isMounted;

  constructor(props: Props) {
    super(props);
    this._isMounted = false;

    this.state = {
      files: {},
      rejectedFiles: {},
      uploadedFiles: {},
      videoUrlValue: '',
      videoUrls: null,
      videoUrlError: false,
      archiveUrl: '',
      archiveUrlError: false
    };
  }

  componentDidMount(): void {
    this._isMounted = true;
  }

  componentWillUnmount(): void {
    this._isMounted = false;
  }

  onDrop = async (acceptedFiles: Array<any>, fileRejections: any) => {  // eslint-disable-line @typescript-eslint/no-explicit-any
    const files: Files = {};

    acceptedFiles.forEach( file => {
      if (file && !this.state.files.hasOwnProperty(file.name)) {
        const
          fileUUID = uuid(),
          fileProps = {
            uuid: fileUUID,
            name: file.name,
            size: file.size,
            type: file.type,
            uploaded: false,
            original: file,
          };
        console.log(file.type);
        
        // Create the thumb/preview if it's an image.
        if (file.type.includes('image')) {
          Object.assign(fileProps, { preview: URL.createObjectURL(file) });
        }

        if (file.type.includes('audio')) {
          Object.assign(fileProps, { preview: '/placeholders/audio-icon.png' })
        }

//         if (file.type.includes('quicktime')) {
//           Object.assign(fileRejections, file);
//           if (!this._isMounted) {
//  return; 
// }
//           this.setState({ errorMessage: <>
//               We currently do not accept .MOV or QuickTime files, we&apos;re working to support this.<br/>
//               For now we suggest converting your file to .MP4 using <a href="https://handbrake.fr/" rel="noreferrer noopener" target="_blank">HandBrake</a>
//           </>});
//           return;
//         }

        if (file.name.toLowerCase().includes('ad-banner')) {
          Object.assign(fileRejections, file);
          if (!this._isMounted) {
            return; 
          }
          this.setState({ errorMessage: <>
              We currently do not accept files with filenames that include &lsquo;ad-banner&rsquo; (regardless of case) due to this being blocked by ad blocker software.
          </>});
          return;
        }

        if (file.name.toLowerCase().includes('#') || file.name.toLowerCase().includes('+')) {
          Object.assign(fileRejections, file);
          if (!this._isMounted) {
            return; 
          }
          this.setState({ errorMessage: <>
              We do not accept files with filenames that include the &lsquo;#&rsquo; symbol or &lsquo;+&rsquo;, please rename and upload again.
          </>});
          return;
        }

        Object.assign(files, { [file.name]: fileProps });
      }
    });

    if (Object.keys(files).length) {
      const state = {
        files: {...this.state.files, ...files},
        rejectedFiles: fileRejections
      };

      if (!this._isMounted) {
 return; 
}
      this.setState(state, async () => {
        // await this.uploadToS3(files);
      });
    }
  };


  addVideoEmbed = async (): Promise<void> => {
    const newUrl = last(this.state.videoUrls);
    const response = await API.put('tba21', 'contributor/items/create', {body: {url: newUrl}});
    console.log(response);
    const file = {
      uuid: uuid(),
      name: last(this.state.videoUrls),
      preview: await getEmbedVideoThumbnailUrl(newUrl),
      size: 0,
      type: 'VideoEmbed',
      uploaded: true,
      s3key: response.s3_key,
      original: true,
      url: newUrl
    };
    const myid = file.uuid;

    if (!this._isMounted) return
    this.setState({ files: {...this.state.files, ...{[myid]: file}}});

    // Callback a single key
    this.props.callback( file.s3key, file );
  }

  addWebsiteArchive = async (websiteUrl) => {
    const context: React.ContextType<typeof AuthContext> = this.context
    const userCredentials = await Auth.currentCredentials()
    const url = new URL(websiteUrl)

    const file = {
      uuid: uuid(),
      name: url.hostname.replaceAll('.', '-'),
      preview: '/placeholders/website-icon.png',
      size: 0,
      type: 'WebArchive',
      uploaded: true,
      s3key: '',
      original: true,
      url: websiteUrl
    }

    file.s3key = `private/${userCredentials.identityId}/${context.uuid}/${file.uuid}-${file.name}.wacz`

    const createItemResponse = await API.put(
      'tba21',
      'contributor/items/create',
      { body: { url: websiteUrl, type: 'WebArchive', s3key: file.s3key } })

    if (!createItemResponse.success) {
      this.setState({ errorMessage: 'Error creating item' })
      return
    }

    this.setState({ files: { ...this.state.files, [file.uuid]: file} })
    this.props.callback( file.s3key, file )
  }

  handleUploadClick = async () => {
    await this.uploadToS3(this.state.files)
    this.setState({ files: {} })
  }

  uploadToS3 = async (files: Files): Promise<void> => {
    const uploads = Object.values(files).map( async (file: File) => {
      const
        context: React.ContextType<typeof AuthContext> = this.context,
        filename = `${context.uuid}/${file.uuid}-${file.name}`,
        userCredentials = await Auth.currentCredentials();

      try {
        const result: any = await Storage.put(filename, file.original, {// eslint-disable-line @typescript-eslint/no-explicit-any
          level: 'private',
          contentType: file.type,
          progressCallback(progress: { loaded: number; total: number}) {
            $(`#${file.uuid} .progress-bar`).width(`${progress.loaded / progress.total * 100}%`);
          }
        });
        file.uploaded = true;
        
        // Add private/UUID to the s3key as we only get the files key back
        // We store the s3key as private/uuid in the database.
        file.s3key = `private/${userCredentials.identityId}/${result.key}`;
        console.log('uploaded', file, this._isMounted);
        
        $(`#${file.uuid}`).fadeOut();
        this.setState({ uploadedFiles: {...files}});

        // Callback a single key
        console.log('uploaded calling back');
        this.props.callback( file.s3key, file );

      } catch (e: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
        console.log('error', e);
      }
    })

    await Promise.all(uploads)
  };

  renderRejectedFiles() {
    return Object.values(this.state.rejectedFiles).map(rejection => {
      return <li key={rejection.file.name}> {rejection.file.type} file: {rejection.file.name} - {rejection.file.size} bytes </li>;
    });
  }

  setFileProp(fileName: string, propName: string, value: any) {
    const files = { ...this.state.files }
    files[fileName][propName] = value
    this.setState({ files })
  }

  render(): React.ReactNode {
    const thumbs = Object.values(this.state.files).map(file => {
      const simpleType = file.type.split('/')[0]
      const isLosslessAudio = ['audio/flac', 'audio/x-flac', 'audio/wav', 'audio/x-wav', 'audio/vnd.wav'].includes(file.type)

      const uploaded = classNames(
        'item-thumbnail',
        simpleType.toLowerCase(),
        file.uploaded ? 'uploaded' : 'pending'
      )

      return (
        ['VideoEmbed', 'WebArchive', 'audio'].includes(simpleType)
          ? <Col xs="12" md="6" key={file.name}>
              <div className={uploaded}>
                {file.preview ? <img alt={file.name} className="img-fluid" src={file.preview} /> : <>{file.name}</>}
                <span>{ file.url || file.name }</span>
                <div>
                  { isLosslessAudio &&
                    <Popup
                      className="tooltip"
                      trigger={ () => <span/> }
                      onOpen={ d => console.log(d) }
                      position="right center"
                      open={ file.lossless === undefined}
                      closeOnDocumentClick={false}
                    >
                      <div className="py-2 px-1">
                        <div className="mb-2">This file looks like it might contain lossless audio.<br/>Would you like to keep the lossless version available after uploading?</div>
                        <div className="text-center">
                          <input type="hidden" role="none" />
                          <button
                            className="button button--small button--compact mr-2"
                            onClick={ () => this.setFileProp(file.name, 'lossless', true) }
                          >
                            Yes
                          </button>
                          <button
                            className="button button--small button--compact"
                            onClick={ () => this.setFileProp(file.name, 'lossless', false) }
                            autoFocus
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </Popup>
                  }
                </div>
              </div>
            </Col>
          : <Col xs="12" md="6" lg="2" key={file.name}>
              <div className={uploaded}>
                {file.preview ? <img alt={file.name} className="img-fluid" src={file.preview} /> : <>{file.name}</>}
                <Progress id={file.uuid} value={0} max={file.size} />
              </div>
            </Col>
      );
    });

    // Force a resize event to fix popup positioning
    setTimeout(() => window.dispatchEvent(new Event('resize')), 50)

    const hasFiles = this.state.files && Object.keys(this.state.files).length > 0

    return (
      <>
        <ErrorMessage message={this.state.errorMessage} />

        <div className="dropzone_wrapper container-fluid">
          <Dropzone
            onDrop={this.onDrop}
            accept="image/jpeg, image/gif, image/png, image/svg+xml, application/pdf, audio/*, text/*, video/*, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          >
            {({getRootProps, getInputProps, isDragActive, isDragReject, isDragAccept}) => (
              <div {...getRootProps()} className={ 'dropzone' + (isDragAccept ? ' active' : '') }>
                <input {...getInputProps()} />
                { !isDragActive &&
                  <>
                    <h2 className="text-title mb-4">Click here or drop a file to upload!</h2>
                    <span className="text-lead">Video thumbnail can be manually specified by editing the item &gt;1hr after uploading.</span>
                  </>
                }
                { isDragReject && <span className="text-title">File type not accepted, sorry!</span> }
                { isDragAccept && <span className="text-title">Drop!</span> }
              </div>
            )}
          </Dropzone>
        </div>
        <div>
          <Form
            className="text-center"
            onSubmit={ (e) => {
              e.preventDefault()
              this.addVideoEmbed()
            } }
          >
            <FormFeedback valid>You need to enter a valid YouTube or </FormFeedback>
            <Input
              type="url"
              className="url form-control--large mt-4"
              placeholder="...or add a YouTube or Vimeo video by entering a URL starting with https://"
              valid={this.state.videoUrlError}
              autoComplete="false"
              onChange={(e) => {
                if (e.target.value.startsWith('https://youtu.be/') || e.target.value.startsWith('https://youtube.com') || e.target.value.startsWith('https://www.youtube.com/') || e.target.value.startsWith('https://vimeo.com') || e.target.value.startsWith('https://www.vimeo.com') ) {
                  this.setState({errorMessage: undefined});
                  if (this.state.videoUrls) {
                    this.setState({videoUrls: this.state.videoUrls!.concat([e.target.value])}); 
                   } else { 
                     this.setState({videoUrls: [e.target.value]});
                   }
                } else {
                  this.setState({ errorMessage: <>
                    Please enter a valid a YouTube or Vimeo video by entering a url starting with https://
                </>});
                }
              }}
            />
            { this.state.videoUrlValue && !this.state.errorMessage &&
              <button className="button mt-3">
                Submit URL
              </button>
            }
          </Form>
        </div>

        { this.context.authorisation.hasOwnProperty('admin') && (
          <div>
            <Form
              className="text-center"
              onSubmit={ (e) => {
                e.preventDefault()
                this.addWebsiteArchive(this.state.archiveUrl)
                this.setState({ archiveUrl: '' })
                e.target[0].value = ''
              } }
            >
              <FormFeedback valid>You need to enter a valid URL</FormFeedback>
              <Input
                type="url"
                className="url form-control--large mt-4"
                placeholder="...or archive an external website by entering its URL"
                valid={ this.state.archiveUrlError }
                autoComplete="false"
                onChange={e => {
                  try {
                    new URL(e.target.value)
                    this.setState({ archiveUrl: e.target.value, errorMessage: undefined })
                  } catch (error) {
                    this.setState({ errorMessage: <>Please enter a valid URL</> })
                  }
                }}
              />
              { this.state.archiveUrl && !this.state.errorMessage &&
                <button className="button mt-3">
                  Submit URL
                </button>
              }
            </Form>
          </div>
        )}

        { hasFiles &&
          <div className="mt-5">
            <h2 className="text-title text-uppercase pt-2 mb-3">1/ Upload files</h2>
            <Row className="preview item-thumbnails">
              {thumbs}
            </Row>
            <div className="text-center mt-5">
              <button className="button" onClick={ () => this.handleUploadClick() }>
                Upload
              </button>
            </div>
          </div>
        }
      </>
    );
  }
}
